import Grid from "@mui/material/Grid";
import { Tag } from 'antd';
import Typography from "@mui/material/Typography";

function DataCard(props) {
  const filterTitle = (title) => {
    var i,
      frags = title.split("_");
    for (i = 0; i < frags.length; i++) {
      frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
    }
    return frags.join(" ");
  };

  var count_arr = [];

  if (props.count === 3) {
    count_arr = [6, 4, 4];
  } else if (props.count === 4) {
    count_arr = [6, 6, 3];
  }
  else if (props.count === 5) {
    count_arr = [6, 6, 2.4];
  }
  else if (props.count === 2) {
    count_arr = [6, 6, 6];
  } else {
    count_arr = [12, 12, 12];
  }
  return (
    <Grid item xs={count_arr[0]} sm={count_arr[1]} md={count_arr[2]} p={2}>
      <Grid direction="column">
        <Typography
          variant="button"
          fontWeight="bold"
          color="secondary"
          textTransform="capitalize"
        >
          {filterTitle(props.title)}
        </Typography>
        {
          props.slot === true ? (
            props.value === 'null' || props.value === null || props.value === '8:00 am' ? (
              <Typography variant="subtitle2" fontWeight="regular">
                <Tag color="geekblue" style={{}}>
                  {'null'.toUpperCase()}
                </Tag>
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle2" fontWeight="regular">
                  {JSON.parse(props.value).map((timeRange, index) => {
                    let startFormatted, endFormatted;
                    if (timeRange.includes(' - ')) {
                      const [start, end] = timeRange.split(' - ');
                      const startTime = start.split(':');
                      const startHour = parseInt(startTime[0]);
                      const startSuffix = startHour >= 12 ? 'PM' : 'AM';
                      const endTime = end.split(':');
                      const endHour = parseInt(endTime[0]);
                      const endSuffix = endHour >= 12 ? 'PM' : 'AM';
                      startFormatted = `${start} ${startSuffix}`;
                      endFormatted = `${end} ${endSuffix}`;
                    } else {
                      const [startTime, endTime] = timeRange.split('-');
                      const startTimeFormatted = startTime.trim();
                      const startHour = parseInt(startTimeFormatted.split(':')[0]);
                      const startSuffix = startHour >= 12 ? 'PM' : 'AM';
                      const endTimeFormatted = endTime.trim();
                      const endHour = parseInt(endTimeFormatted.split(':')[0]);
                      const endSuffix = endHour >= 12 ? 'PM' : 'AM';
                      startFormatted = `${startTimeFormatted} ${startSuffix}`;
                      endFormatted = `${endTimeFormatted} ${endSuffix}`;
                    }
                    const formattedRange = `${startFormatted} - ${endFormatted}`;
                    return <Tag color="geekblue" key={index}>{formattedRange}</Tag>;
                  })}
                </Typography>
              </>
            )
          ) : (
            <Typography variant="subtitle2" fontWeight="regular">
              {props.value}
            </Typography>
          )
        }
      </Grid>
    </Grid>
  );
}

export default DataCard;
