import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Typography, Card, Grid } from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataCard from "../../components/DataCard";
import { format } from "date-fns";

interface ChamberData {
    chamber_id?: number;
    first_name?: string;
    last_name?: string;
    address?: string;
    city?: string;
    state?: string;
    pincode?: string;
    gst_certificate?: string;
    pan?: string;
    cin?: string;
    logo?: string;
    organization_name?: string;
    organization_category?: string;
    gst_number?: string;
    contact_person?: string;
    contact_number?: string;
    profit?: string;
    merchant_key?: string;
    services?: string;
    subscription_from?: string;
    subscription_till?: string;
    status?: string;
    payment?: string;
    payment_status?: boolean;
    is_national_chamber?: boolean;
    parent_id?: number;
    createdAt?: string;
    updatedAt?: string;
}

interface LocationState {
    chamber?: ChamberData;
}

const ChapterView: React.FC = () => {
    const location = useLocation();
    const { chamber } = location.state as LocationState || {};

    if (!chamber) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <Box pt={6} pb={3}>
                    <Typography variant="h6" align="center">
                        No data available. Please go back and select a chamber.
                    </Typography>
                </Box>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box pt={6} pb={3}>
                <Grid container spacing={6} justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Card>
                            <Box pt={3}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    px={3}
                                    py={2}
                                >
                                    <Typography
                                        variant="h5"
                                        color="black"
                                        sx={{ ml: 1, mb: -2 }}
                                    >
                                        Chapters
                                    </Typography>
                                </Box>
                            </Box>
                            <Box pt={1}>
                                <Grid container direction="row" p={2}>
                                    <DataCard title="ID" value={chamber.chamber_id} count={3} />
                                    <DataCard title="Name" value={`${chamber.first_name} ${chamber.last_name}`} count={3} />
                                    <DataCard title="Address" value={chamber.address} count={3} />
                                    <DataCard title="City" value={chamber.city} count={3} />
                                    <DataCard title="State" value={chamber.state} count={3} />
                                    <DataCard title="Pin Code" value={chamber.pincode} count={3} />
                                    <DataCard title="Organization Name" value={chamber.organization_name} count={3} />
                                    <DataCard title="GST Number" value={chamber.gst_number} count={3} />
                                    <DataCard title="Contact Person" value={chamber.contact_person} count={3} />
                                    <DataCard title="Contact Number" value={chamber.contact_number} count={3} />
                                    <DataCard title="Subscription From" value={format(new Date(chamber.subscription_from!), "dd/MM/yyyy")} count={3} />
                                    <DataCard title="Subscription Till" value={format(new Date(chamber.subscription_till!), "dd/MM/yyyy")} count={3} />
                                    <DataCard title="Payment Status" value={chamber.payment_status ? "Paid" : "Unpaid"} count={3} />
                                    <DataCard title="Created At" value={format(new Date(chamber.createdAt!), "dd/MM/yyyy")} count={3} />
                                    <DataCard title="Updated At" value={format(new Date(chamber.updatedAt!), "dd/MM/yyyy")} count={3} />
                                </Grid>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default ChapterView;
