import { Select, styled } from "@mui/material";


const WhiteSelect = styled(Select)({
  // color: "white",
  // borderColor: "#6c5ce7",
  "&:focus": {
    color: "#6c5ce7",
    border: "1px #6c5ce7 solid"
  },
  "& .selected": {
    color: "black",
  
  },
  "&:hover": {
    color: "#6c5ce7",
    border: "1px #6c5ce7 solid"
  },
  "&:active":{
    border:"1px #6c5ce7 solid"
  },
  "&:focus-within":{
    border: "1px #6c5ce7 solid"
  },

});
export default WhiteSelect;

