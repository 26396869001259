import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { Box, Typography, Button } from "@mui/material";

// Custom components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Redux
import { RootState, useAppDispatch, useAppSelector } from "../../Redux/store";
import { GetChambers } from "../../Redux/action";

const Chapter: React.FC = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { chambers } = useAppSelector(
        (store: RootState) => store.GetChambersReducer
    );
    const chambersData = chambers || [];

    useEffect(() => {
        const handleGetChambers = async () => {
            try {
                await dispatch<any>(GetChambers());
            } catch (error) {
                console.error("Error fetching Chamber data:", error);
            }
        };
        handleGetChambers();
    }, [dispatch]);

    const columns = [
        { Header: "Chamber Name", accessor: "name", align: "left" },
        { Header: "Phone Number", accessor: "contact_number", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "Address", accessor: "address", align: "left" },
        { Header: "Actions", accessor: "actions", align: "center" },
    ];

    const rows = chambersData.map((chamber) => ({
        chamber_id: chamber.chamber_id,
        name: chamber.first_name + " " + chamber.last_name,
        contact_number: chamber.contact_number,
        email: chamber.email,
        address: chamber.address,
        actions: (
            <Button
                color="primary"
                onClick={() =>
                    navigate(`/chapter/${chamber.chamber_id}`, {
                        state: { chamber },
                    })
                }
            >
                View
            </Button>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box pt={6} pb={3}>
                <Grid container spacing={6} justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Card>
                            <Box pt={3}>
                                <Box
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    px={3}
                                    py={2}
                                >
                                    <Typography
                                        variant="h5"
                                        color="black"
                                        sx={{ ml: 1, mb: 2 }}
                                    >
                                        Chapters
                                    </Typography>
                                </Box>
                            </Box>
                            <Box pt={3}>
                                {chambersData.length > 0 ? (
                                    <DataTable
                                        table={{ columns, rows }}
                                        isSorted={false}
                                        entriesPerPage={false}
                                        showTotalEntries={false}
                                        noEndBorder
                                    />
                                ) : (
                                    <Grid
                                        style={{
                                            display: "grid",
                                            placeItems: "center",
                                            margin: "15.7%",
                                        }}
                                    >
                                        <img
                                            src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                                            width={"80px"}
                                            height={"80px"}
                                            alt="No data found"
                                        />
                                        <Typography fontSize="12px">
                                            No chapters found.
                                        </Typography>
                                    </Grid>
                                )}
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default Chapter;
