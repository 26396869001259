// Material Dashboard 2 React example components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";

// @mui material components
import { Grid, TextField, Card, Button, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import MDButton from "../../../components/MDButton";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";
import MDBox from "../../../components/MDBox";
import { useDispatch } from "react-redux";
import { useAppSelector, RootState } from "../../../Redux/store";
import {
  AddEvents,
  GetEventsById,
  UpdateEventById,
} from "../../../Redux/action";
import { Event as EventInterface } from "../../../utils/types";
import useFormValidator from "../../../utils/form_validation";
import { eventValidations } from "../../../utils/user_Validation";

const Event: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state?.type;
  const idFromLocation = location?.state?.id;
  const eventById = useAppSelector(
    (state: RootState) => state.EventReducer.eventById
  );
  // console.log("eventById", eventById);
  // const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const chamber_id = localStorage.getItem("user_id");

  const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  };
  const payloadObj = {
    chamber_id: +chamber_id! as number,
    event_name: "",
    event_date: "",
    booking_price: 0,
    is_free : true,
    event_time_from: "",
    event_time_till: "",
    event_agenda: "",
    event_desc: "",
    event_registration_date: "",
    event_date_end: "",
  };

  const [payload, setPayload] = useState<EventInterface>(
    idFromLocation ? (eventById as EventInterface) : payloadObj
  );

  const { errors, validateForm } = useFormValidator(payload, eventValidations);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "booking_price") {
      const priceValue = parseFloat(value);
      setPayload({
        ...payload,
        booking_price: priceValue,
        is_free: priceValue === 0,
      });
    } else {
      setPayload({ ...payload, [name]: value });
    }
  };
  const handleResponseAddEvents = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${"Event added successfully."}`, {
        variant: "success",
      });
      navigate("/event-management");
    } else {
      if (res?.data?.message) {
        enqueueSnackbar(`${res?.data?.message}`, { variant: "error" });
      } else if (res?.data?.error) {
        enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
      } else {
        enqueueSnackbar(`Something went wrong, Please check all fields.`, {
          variant: "error",
        });
      }
    }
  };

  const handleResponseUpdateEvents = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${res?.data?.message}`, { variant: "success" });
      navigate("/event-management");
    } else {
      enqueueSnackbar(`${res?.data?.error}`, { variant: "error" });
    }
  };

  const handleEventSubmit = async () => {
    const validateFormError = validateForm();
    if (!validateFormError) {
      if (type === "add") {
        
        dispatch<any>(AddEvents(payload, handleResponseAddEvents));
      } else if (type === "edit" && idFromLocation) {
        dispatch<any>(
          UpdateEventById(idFromLocation, payload, handleResponseUpdateEvents)
        );
      }
    } else {
      enqueueSnackbar(
        "Form validation failed. Please fill in all required fields.",
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    if (idFromLocation) {
      dispatch<any>(GetEventsById(idFromLocation));
    }
  }, [idFromLocation, dispatch]);

  useEffect(() => {
    if (eventById) {
      setPayload(eventById);
    }
  }, [eventById]);

  useEffect(() => {
    if (type === "add") {
      setPayload(payloadObj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("idFromLocation", idFromLocation);
  // console.log("payload", payload);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: -2 }}
                  >
                    Event Management
                  </Typography>
                </Box>
              </Box>
      <MDBox
        {...({} as any)}
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        variant="gradient"
        borderRadius="lg"
      >
        <MDTypography {...({} as any)} variant="body2" pb={2}>
          Event Details
        </MDTypography>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          gap={3}
          pb={2}
        >
          <MDInput
            {...({} as any)}
            required
            type="text"
            label="Event Name"
            name="event_name"
            value={payload?.event_name || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <MDInput
            {...({} as any)}
            required
            type="number"
            label="Event price"
            name="booking_price"
            value={payload?.booking_price}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <TextField
            {...({} as any)}
            required
            type="text"
            label="Agenda"
            name="event_agenda"
            multiline
            value={payload?.event_agenda || ""}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />
          <TextField
            {...({} as any)}
            required
            type="text"
            label="Description"
            name="event_desc"
            multiline
            value={payload?.event_desc}
            margin="dense"
            sx={widthStyle}
            onChange={handleChange}
          />

        </Grid>
        <MDTypography {...({} as any)} variant="h6" pt={2} fontWeightLighter>
          Event schedule on
        </MDTypography>
        <Grid container direction="row" justifyContent="space-between">
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={"3 1"}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Event Date
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="date"
              // label=""
              name="event_date"
              value={payload?.event_date?.slice(0, 10) || ""}
              onChange={handleChange}
            />
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            // gap={"3 1"}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Event Ending date
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="date"
              // label="Last registration date"
              name="event_date_end"
              value={payload?.event_date_end}
              margin="dense"
              // sx={widthStyle}
              onChange={handleChange}
            />
          </Grid>

        </Grid>
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            // gap={"3 1"}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Event last registration date
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="date"
              // label="Last registration date"
              name="event_registration_date"
              value={payload?.event_registration_date}
              margin="dense"
              // sx={widthStyle}
              onChange={handleChange}
            />
          </Grid>
        <Grid container direction="row" justifyContent="space-between">
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={"3 1"}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Event is starting at
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="time"
              // label=""
              name="event_time_from"
              value={payload?.event_time_from || ""}
              onChange={handleChange}
            />
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="space-between"
            gap={"3 1"}
            pt={2}
            sx={widthStyle}
          >
            <MDTypography
              {...({} as any)}
              variant="h6"
              sx={{ fontSize: "14px", fontWeight: "400" }}
            >
              Event is ending at
            </MDTypography>
            <MDInput
              {...({} as any)}
              required
              type="time"
              // label=""
              name="event_time_till"
              value={payload?.event_time_till || ""}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
        {/* <Grid container justifyContent="space-between" pt={""}>
          <Grid
            container
            justifyContent="space-between"
            pt={4}
            flexDirection="row"
            sx={{
              "@media (max-width: 600px)": {
                flexDirection: "column",
              },
            }}
          >
            <Grid item xs={4}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                pt={2}
                fontWeightLighter
              >
                {showDoc ? "Update Logo" : "Add Logo"}
                <span style={{ fontSize: "14px", fontWeight: "400" }}>
                  {" "}
                  (Preferred dimensions are 1350 x 376)
                </span>
              </MDTypography>
              <MDInput
                {...({} as any)}
                type="file"
                name="logo"
                margin="dense"
                sx={{ width: "100%" }}
                onChange={handleChange}
              />
              {showDoc && (
                <>
                  <MDButton
                    {...({} as any)}
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(`${docUrl}${payload?.logo}`, "_blank");
                    }}
                  >
                    View Document
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    component="a"
                    variant="body2"
                    onClick={() => {
                      const fileUrl = `${docUrl}${payload?.logo}`;
                      saveAs(fileUrl, `${payload?.logo}`);
                    }}
                  >
                    Download Document
                  </MDButton>
                </>
              )}
            </Grid>
          </Grid>
        </Grid> */}

        {(errors?.event_name && (
          <span style={{ fontSize: "12px", color: "red" }}>
            {errors?.event_name}
          </span>
        )) ||
          (errors?.event_date && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_date}
            </span>
          )) ||
          (errors?.event_time_from && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_time_from}
            </span>
          )) ||
          (errors?.event_time_till && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_time_till}
            </span>
          )) ||
          (errors?.event_agenda && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_agenda}
            </span>
          )) ||
          (errors?.event_desc && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_desc}
            </span>
          )) ||
          (errors?.event_registration_date && (
            <span style={{ fontSize: "12px", color: "red" }}>
              {errors?.event_registration_date}
            </span>
          ))}
      </MDBox>
      <Grid container direction="row" justifyContent="flex-end" p={2} pb={5} pr={8}>
        <MDButton
          {...({} as any)}
          variant="gradient"
          style={{ color: 'white', backgroundColor: '#6c5ce7' }}
          onClick={handleEventSubmit}
        >
          {type === "add" ? "Add Event" : "Update"}
        </MDButton>
      </Grid>
      </Card>
      </Grid>
      </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default Event;
