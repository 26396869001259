import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import axios from "axios";
import { Grid, SelectChangeEvent } from "@mui/material";
import { useSnackbar } from "notistack";

import { FormControl, Select, MenuItem, Box, Card, Button,Typography } from "@mui/material";
import { Hall } from "../../../utils/types";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MDInput from "../../../components/MDInput";

const HallBooking: React.FC = () => {
  const [selectedHall, setSelectedHall] = useState("");
  const [hallsData, setHallsData] = useState<Hall[]>([]);
  const [loading, setLoading] = useState(true);
  const { enqueueSnackbar } = useSnackbar();
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const Id = localStorage.getItem("user_id");
  const [singleSlotResponse, setSingleSlotResponse] = useState<any>(null);
  const [daysResponse, setDaysResponse] = useState(null);
  const token = localStorage.getItem("token")
  const handleHallChange = (event: SelectChangeEvent<string>) => {
    setSelectedHall(event.target.value);
  };

  const handleFromDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFromDate(event.target.value);
  };

  const handleToDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToDate(event.target.value);
  };

  const checkSingleSlot = async () => {
    if (!selectedHall || !fromDate) {
      enqueueSnackbar("Please select a hall and enter the from date.", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await axios.post(
        "https://api.pixacci.com/booking/getallbooking",
        {
          chamber_id: Id,
          hall_id: selectedHall,
          booking_date: fromDate,
        }
      );

      setSingleSlotResponse(response.data.data);
    } catch (error) {
      console.error("Error checking single slot:", error);
      enqueueSnackbar("An error occurred while checking single slot.", {
        variant: "error",
      });
    }
  };

  const checkDays = async () => {
    if (!selectedHall || !fromDate || !toDate) {
      enqueueSnackbar(
        "Please select a hall and enter both from and to dates.",
        { variant: "error" }
      );
      return;
    }

    try {
      const response = await axios.post(
        "https://api.pixacci.com/booking/getallbooking",
        {
          chamber_id: Id,
          hall_id: selectedHall,
          booking_date: fromDate,
          booking_till: toDate,
        }, {
          headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      );

      setDaysResponse(response.data.message);
    } catch (error) {
      console.error("Error checking days:", error);
      enqueueSnackbar("An error occurred while checking days.");
    }
  };

  const handleCheck = async () => {
    if (!selectedHall || !fromDate || !toDate) {
      enqueueSnackbar(
        "Please select a hall and enter both from and to dates.",
        { variant: "error" }
      );
      return;
    }

    if (fromDate === toDate) {
      try {
        const response = await axios.post(
          "https://api.pixacci.com/booking/getallbooking",
          {
            chamber_id: Id,
            hall_id: selectedHall,
            booking_date: fromDate,
          }, {
            headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );

        setSingleSlotResponse(response.data.data);
        setDaysResponse(null);
      } catch (error) {
        console.error("Error checking single slot:", error);
        enqueueSnackbar("An error occurred while checking single slot.", {
          variant: "error",
        });
      }
    } else {
      try {
        const response = await axios.post(
          "https://api.pixacci.com/booking/getallbooking",
          {
            chamber_id: Id,
            hall_id: selectedHall,
            booking_date: fromDate,
            booking_till: toDate,
          }, {
            headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );

        setDaysResponse(response.data.message);
        setSingleSlotResponse(null);
      } catch (error) {
        console.error("Error checking days:", error);
        enqueueSnackbar("An error occurred while checking days.", {
          variant: "error",
        });
      }
    }
  };

  useEffect(() => {
    const fetchHallsData = async () => {
      try {
        const response = await axios.post(
          "https://api.pixacci.com/hall/chamber",
          {
            id: Id,
          },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        );

        setHallsData(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching hall data:", error);
      }
    };

    fetchHallsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: -2 }}
                  >
                    Hall Booking
                  </Typography>
                </Box>
              </Box>
      <MDBox
        {...({} as any)}
        mx={2}
        mt={3}
        py={3}
        p={4}
        mb={2}
        variant="gradient"
        borderRadius="lg"
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={3}
          pb={0.5}
        >
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <label>
                  <MDTypography
                    {...({} as any)}
                    variant="h6"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    From Date
                  </MDTypography>
                  <MDInput
                    {...({} as any)}
                    type="date"
                    name=""
                    margin="dense"
                    fullWidth
                    required
                    onChange={handleFromDateChange}
                  />
                </label>
              </Grid>
              <Grid item xs={6}>
                <label>
                  <MDTypography
                    {...({} as any)}
                    variant="h6"
                    style={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    To Date
                  </MDTypography>
                  <MDInput
                    {...({} as any)}
                    type="date"
                    name=""
                    margin="dense"
                    fullWidth
                    required
                    onChange={handleToDateChange}
                  />
                </label>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <label>
              <MDTypography
                {...({} as any)}
                variant="h6"
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                Select Hall
              </MDTypography>
              <FormControl fullWidth>
                <Select
                  style={{ height: "45px", color: "white" }}
                  value={selectedHall}
                  onChange={handleHallChange}
                >
                  {hallsData.map((hall) => (
                    <MenuItem key={hall.id} value={hall.id}>
                      {hall?.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </label>
          </Grid>
        </Grid>
        <MDBox {...({} as any)} mt={2}>
          <MDButton
            {...({} as any)}
            variant="gradient"
            style={{ color: 'white', backgroundColor: '#6c5ce7' }}
            onClick={handleCheck}
          >
            View Bookings
          </MDButton>
        </MDBox>
        <MDBox {...({} as any)} mt={2}>
          {singleSlotResponse !== null && (
            <MDBox {...({} as any)} mt={2}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                Single Slot Availability:
              </MDTypography>
              <MDBox
                {...({} as any)}
                mt={1}
                p={1}
                style={{
                  borderRadius: "4px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              >
                {singleSlotResponse?.map((slot: string, index: number) => {
                  const [start, end] = slot.split(" - ").map((time: string) => {
                    const [hours, minutes] = time.split(":");
                    const formattedHours = parseInt(hours, 10) % 12 || 12;
                    const period = parseInt(hours, 10) < 12 ? "AM" : "PM";
                    return `${formattedHours}:${minutes} ${period}`;
                  });

                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "10px",
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`timeCheckbox-${index}`}
                        style={{ marginRight: "8px" }}
                      />
                      <label htmlFor={`timeCheckbox-${index}`}>
                        <MDTypography
                          {...({} as any)}
                          variant="body2"
                          style={{
                            fontSize: "14px",
                            whiteSpace: "pre-wrap",
                          }}
                        >
                          {`${start} - ${end}`}
                        </MDTypography>
                      </label>
                    </div>
                  );
                })}
              </MDBox>
            </MDBox>
          )}
          {daysResponse !== null && (
            <MDBox {...({} as any)} mt={2}>
              <MDTypography
                {...({} as any)}
                variant="h6"
                style={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                Day Slot Availability:
              </MDTypography>
              <MDBox
                {...({} as any)}
                mt={1}
                p={1}
                style={{ maxHeight: "300px" }}
              >
                <MDTypography
                  {...({} as any)}
                  variant="body2"
                  style={{ fontSize: "16px", whiteSpace: "pre-wrap" }}
                >
                  {JSON.stringify(daysResponse, null, 2).replace(/["']/g, "")}
                </MDTypography>
              </MDBox>
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      </Card>
      </Grid>
      </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default HallBooking;
