import { jwtDecode } from 'jwt-decode';
import { enqueueSnackbar } from 'notistack';

export function isTokenExpired(token) {
    if (!token) return true;
    const decoded = jwtDecode(token);
    return decoded.exp * 1000 < Date.now();
}

export function logout() {
    localStorage.removeItem('token'); 
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('service_ids');
    localStorage.removeItem('role');
    localStorage.removeItem('user_id');
    window.location.href = "https://app.pixacci.com/landing-page";
}

export function checkAuth(timeout = 1000) {
    const token = localStorage.getItem('token');
    if (isTokenExpired(token)) {
      enqueueSnackbar('Login is expired, Sign-in again to continue', { variant: 'error' });
      setTimeout(() => logout(), timeout);
      return false;
    }
    return true;
  }