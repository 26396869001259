// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import WhiteSelect from "../../components/WhiteSelect";
import { useEffect, useState } from "react";
import { DeleteHallById, GetHalls, GetChambers } from "../../Redux/action";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { toCapitalize } from "../../utils/capitalize";

const HallManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    capacity,
  }: {
    image: string;
    name: string;
    capacity: number;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        //   name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          having capacity of {capacity}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ price, discount }: { price: number; discount: number }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {price}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        with discount of Rs. {discount}
      </MDTypography>
    </MDBox>
  );

  const [isNationalChamberOrAggregator, setIsNationalChamberOrAggregator] =
    useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { halls, loading } = useAppSelector(
    (store: RootState) => store.HallManagementReducer
  );
  const { chambers, loadingc } = useAppSelector(
    (store: RootState) => store.GetChambersReducer
  );
  const hallsData = halls || [];
  const chambersData = chambers || [];
  const [selectedChamberId, setSelectedChamberId] = useState<null | string>("");

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/hall/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    try {
      await dispatch<any>(DeleteHallById(deleteId!));
      const id = localStorage.getItem("user_id") as string;
      dispatch<any>(GetHalls(id));
      handleClose();
      enqueueSnackbar("Hall is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting Hall:", error);
    }
  };

  const handleGetHalls = async () => {
    try {
      const id = localStorage.getItem("user_id") as string;
      await dispatch<any>(GetHalls(id));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleGetChambers = async () => {
    try {
      const isNational = localStorage.getItem("isNationalChamber") as string;
      const isAggregator = localStorage.getItem("isAggregator") as string;
      if (isNational || isAggregator) {
        const boolNationalChamber = JSON.parse(isNational);
        const boolAggregator = JSON.parse(isAggregator);
        if (boolNationalChamber)
          setIsNationalChamberOrAggregator(boolNationalChamber);
        else if (boolAggregator)
          setIsNationalChamberOrAggregator(boolAggregator);

        if (boolNationalChamber || boolAggregator) {
          await dispatch<any>(GetChambers());
        }
      }
    } catch (error) {
      console.error("Error fetching Chamber detail: ", error);
    }
  };

  const handleChamberChange = (event: SelectChangeEvent<any>) => {
    const chamberId = event.target.value;
    if (chamberId === "default") {
      handleGetHalls();
      setSelectedChamberId(chamberId);
      return;
    }

    setSelectedChamberId(chamberId);
    dispatch<any>(GetHalls(chamberId));
  };
  const columns = [
    { Header: "Hall Name", accessor: "hall_name", align: "left" },
    { Header: "City", accessor: "city", align: "left" },
    { Header: "Capacity", accessor: "capacity", align: "left" },
    { Header: "Booking Price", accessor: "booking_price", align: "left" },
    { Header: "Extended Hour Allowed", accessor: "extended_hour_allowed", align: "left" },
    { Header: "Status", accessor: "status", align: "left" },
    ...(selectedChamberId && selectedChamberId !== "default"
      ? []
      : [{ Header: "Actions", accessor: "action", align: "center" }]),
  ];


  const rows = hallsData?.map((hall) => {
    const currentDate = new Date();
    const bookingFrom = new Date(hall.booking_from);
    const bookingTill = new Date(hall.booking_till);
    const isActive = currentDate >= bookingFrom && currentDate <= bookingTill;

    return {
      hall_name: (
        <Author
          image={
            "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
          }
          name={`${toCapitalize(hall?.name)}`}
          capacity={hall.capacity}
        />
      ),
      city: (
        <MDTypography
          {...({} as any)}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {hall?.city}
        </MDTypography>
      ),
      capacity: (
        <MDTypography
          {...({} as any)}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {hall?.capacity}
        </MDTypography>
      ),
      booking_price: (
        <Job price={hall?.booking_price} discount={hall?.current_discount} />
      ),
      extended_hour_allowed: (
        <MDTypography
          {...({} as any)}
          component="a"
          href="#"
          variant="caption"
          color="text"
          fontWeight="medium"
        >
          {toCapitalize(hall?.extended_hour_allowed)}
        </MDTypography>
      ),
      status: (
        <MDBadge
          {...({} as any)}  
          badgeContent={isActive ? "Active" : "Inactive"}
          color={isActive ? "success" : "error"}
          variant="gradient"
          size="sm"
        />
      ),
      ...(selectedChamberId && selectedChamberId !== "default"
        ? {}
        : {
          action: (
            <>
              <Tooltip title="Edit">
                <IconButton
                  color="primary"
                  onClick={() => handleEditClick(hall?.id)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  color="error"
                  onClick={() => {
                    if (hall?.id) handleClickOpen(hall?.id);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ),
        }),
    };
  });

  useEffect(() => {
    handleGetHalls();
    handleGetChambers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography variant="h5" color="black" sx={{ ml: 1, mb: 2 }}>
                    Hall Management
                  </Typography>
                  {isNationalChamberOrAggregator ? (
                    <FormControl>
                      <InputLabel
                        style={{ color: "black", backgroundColor: "white" }}
                      >
                        Chapter
                      </InputLabel>
                      {chambersData && !loadingc ? (
                        <WhiteSelect
                          label="Select Hall"
                          style={{ height: "45px", width: "200px" }}
                          sx={{
                            mb: 2,
                            mr: 1,
                            color: "#6c5ce7",
                            "& .MuiSelect-select": {
                              color: "black",
                            },
                            border: "1px  black solid",
                            "&:hover": {
                              color: "#6c5ce7",
                              border: "1px #6c5ce7 solid",
                            },
                          }}
                          value={selectedChamberId}
                          onChange={handleChamberChange}
                          renderValue={(selectedId) => {
                            const selectedChamber = chambersData.find(
                              (chamber) => chamber.chamber_id === selectedId
                            );
                            return (
                              <div
                                style={{ color: "black" }}
                                className="selected"
                              >
                                {selectedChamberId === "default"
                                  ? "Default"
                                  : `${toCapitalize(
                                      selectedChamber?.first_name
                                    )} ${toCapitalize(
                                      selectedChamber?.last_name
                                    )}`}
                              </div>
                            );
                          }}
                        >
                          {chambersData.map((chamber) => (
                            <MenuItem
                              sx={{ color: "black" }}
                              key={chamber.chamber_id}
                              value={chamber.chamber_id}
                            >
                              {toCapitalize(chamber?.first_name)}{" "}
                              {toCapitalize(chamber?.last_name)}
                            </MenuItem>
                          ))}
                          <MenuItem
                            sx={{ color: "black" }}
                            key={uuidv4()}
                            value={"default"}
                          >
                            {"Default"}
                          </MenuItem>
                        </WhiteSelect>
                      ) : null}
                    </FormControl>
                  ) : null}

                  <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                    <Tooltip title="View Hall Bookings">
                      <Button
                        onClick={() => navigate("/hall-management/booking")}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid",
                          },
                        }}
                      >
                        View Booking
                      </Button>
                    </Tooltip>
                    <Tooltip title="Add a new hall">
                      <Button
                        onClick={() => {
                          navigate("/hall/add", { state: { type: "add" } });
                        }}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid",
                          },
                        }}
                      >
                        Add Hall
                      </Button>
                    </Tooltip>
                  </MDBox>
                </Box>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {hallsData && !loading ? (
                  hallsData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No hall found. Please add a hall to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    Are you sure you want to delete this Hall?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <MDButton {...({} as any)} onClick={handleClose}>
                    Disagree
                  </MDButton>
                  <MDButton
                    {...({} as any)}
                    onClick={handleDeleteClick}
                    autoFocus
                  >
                    Agree
                  </MDButton>
                </DialogActions>
              </Dialog>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default HallManagement;
