import { error } from "console";
import { Chamber, Hall,User } from "../utils/types";
import { actionTypes } from "./action.types";


export interface GetChambersState {
  loadingc: boolean;
  error: boolean;
  chambers: Chamber[];
}

const initialState: GetChambersState = {
  loadingc: false,
  error: false,
  chambers: [],
};

const GetChambersReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETCHAMBERS_LOADING:
      return { ...state, loadingc: true, error: false };
    case actionTypes.GETCHAMBERS_SUCCESS:
      return { ...state, loadingc: false, chambers: payload as Chamber[]};
    case actionTypes.GETCHAMBERS_ERROR:
      return { ...state, loadingc: false, error:true };
    default:
      return state;
  }
};

export default GetChambersReducer;
