import React, { useRef, useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";

import * as XLSX from "xlsx";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React components

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
// Data
import WhiteSelect from "../../components/WhiteSelect";
import { GetUser, GetChambers, DeleteUser, uploadCSV } from "../../Redux/action";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import { SelectChangeEvent, Tooltip, styled, Select, FormControl, InputLabel,MenuItem} from "@mui/material";
import { RootState, useAppDispatch, useAppSelector } from "../../Redux/store";
import MDTypography from "../../components/MDTypography";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDBadge from "../../components/MDBadge";
import MDAvatar from "../../components/MDAvatar";
import { IconButton, Box, Typography, Button, Modal } from '@mui/material';
import POPUploadUserCSV from "../../components/BulkUpload/POPUploadUserCSV";
import EditIcon from '@mui/icons-material/Edit';
import { enqueueSnackbar } from "notistack";
import { toCapitalize } from "../../utils/capitalize";
import axios from "axios";
import { display, width } from "@mui/system";
import MDInput from "../../components/MDInput";


const MemberShip: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const fileInputRef = useRef();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const Author = ({
    image,
    name,
    email,
  }: {
    image: string;
    name: string;
    email: string;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        // name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          {email}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const handleDeleteClick = (id: number) => {
    setSelectedUserId(id);
    setDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (typeof selectedUserId === "number") {
      try {
        await dispatch<any>(DeleteUser(selectedUserId));
        handleGetUser();
      } catch (error) {
        console.error("Error deleting user:", error);
      } finally {
        enqueueSnackbar("Member Deleted Successfully!", {variant: "success"});
        setDeleteDialogOpen(false);
        setSelectedUserId(null);
      }
    } else {
      console.error("Invalid user ID for deletion");
    }
  };

  const cancelDelete = () => {
    setDeleteDialogOpen(false);
    setSelectedUserId(null);
  };

  const Job = ({
    title,
    description,
  }: {
    title: string;
    description: string;
  }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        MD
        color="text"
        fontWeight="medium"
      >
        {title}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        {description}
      </MDTypography>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const docUrl = "https://pixa-chambers-bucket.s3.ap-south-1.amazonaws.com/";
  const { user, loading } = useAppSelector(
    (store: RootState) => store.MembershipReducer
  );
  const {chambers, loadingc} = useAppSelector(
    (store: RootState) => store.GetChambersReducer
  );
  const usersData = user || [];
  const chambersData = chambers || [];

  const [isNationalChamberOrAggregator, setIsNationalChamberOrAggregator] = useState<boolean>(false);
  const [selectedChamberId, setSelectedChamberId]  = useState<null | string>("");

  // console.log(usersData, 'usersData');

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/membership/edit/", { state: { type: "edit", id: id } });
    }
  };

  const columns = [
    { Header: "member name", accessor: "member", width: "45%", align: "left" },
    
    { Header: "phone number", accessor: "phone number", align: "left" },
    { Header: "member since", accessor: "member since", align: "left" },
    { Header: "organization", accessor: "organization", align: "left" },
    { Header: "status", accessor: "status", align: "center" },
    { Header: "subscription till", accessor: "subscription", align: "center" },
    ...(selectedChamberId === "" || selectedChamberId === "default"
      ? [{ Header: "", accessor: "action", align: "center" }]
      : [])
  ];

  const rows = usersData?.map((userData) => ({
    member: (
      <Author
        image={`${docUrl}${userData?.logo}`}
        name={`${toCapitalize(userData?.first_name)} ${toCapitalize(userData?.last_name)}`}
        email={userData.email}
      />
    ),
    "phone number": (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {userData?.phone_number}
      </MDTypography>
    ),
    "member since": (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {userData?.member_since || (new Date()).toLocaleDateString() }
      </MDTypography>
    ),
    organization: (
      <Job
        title={userData?.organization_name}
        description={userData?.organization_category}
      />
    ),
    status: (
      <MDBox {...({} as any)} ml={-1}>
        <MDBadge
          {...({} as any)}
          badgeContent={userData?.status === "online" ? "Active" : "Inactive"}
          color={userData?.status === "online" ? "success" : "dark"}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    subscription: (
      <MDTypography {...({} as any)} variant="caption" color="text" fontWeight="medium">
        {userData?.subscription_from?.slice(0, 10)}
      </MDTypography>
    ),
    action: (
      <Box>
        <IconButton
          onClick={() => handleEditClick(userData?.user_id)}
          sx={{
            color: "#6c5ce7",
            "&:hover": { color: "#4c40e5" },
          }}
        >
          <EditIcon />
        </IconButton>
        <IconButton
          onClick={() => userData?.user_id && handleDeleteClick(userData.user_id)}
          sx={{
            color: "#e74c3c",
            "&:hover": { color: "#c0392b" },
          }}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
    ),
  }));

  const handleGetUser = async () => {
    try {
      const id = localStorage.getItem("user_id") as string;
      await dispatch<any>(GetUser(id));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleGetChambers = async ()=>{
    try{
      const isNational = localStorage.getItem("isNationalChamber") as string;
      const isAggregator = localStorage.getItem("isAggregator") as string ;
      if(isNational || isAggregator){
        const boolNationalChamber=JSON.parse(isNational);
        const boolAggregator = JSON.parse(isAggregator);
        if(boolNationalChamber)setIsNationalChamberOrAggregator(boolNationalChamber);
        else if(boolAggregator)setIsNationalChamberOrAggregator(boolAggregator);
        
        if(boolNationalChamber || boolAggregator){
          await dispatch<any>(GetChambers());
          
        }

      }
    }catch(error){
      console.error("Error fetching Chamber detail: ", error);
    }
  }

  const handleChamberChange = (event: SelectChangeEvent<any>)=>{
    const chamberId = event.target.value;
    if(chamberId==="default"){
      handleGetUser();
      setSelectedChamberId(chamberId);
      return;
    }
    setSelectedChamberId(chamberId);
    dispatch<any>(GetUser(chamberId));

  }

  const [file, setFile] = useState<File | null>(null);
  

  const fileReader = new FileReader();

  const handleOnChangeCSVFile = (e:React.ChangeEvent<HTMLInputElement>) => {
   
    if (e.target.files) {
      const files = e.target.files?.[0];
      setFile(files);
    }
  };

  const handleResponseAddUser = (success = false, res: any) => {
    if (success) {
      enqueueSnackbar(`${'CSV uploaded succesfully.'}`, {
        variant: 'success'
      });
    } else {
      if (res?.data?.message) {
        enqueueSnackbar(`${res?.data?.message}`, { variant: 'error' });
      } else if (res?.data?.error) {
        enqueueSnackbar(`${res?.data?.error}`, { variant: 'error' });
      } else {
        enqueueSnackbar(`Something went wrong, Please try again`, {
          variant: 'error'
        });
      }
    }
  };
  const addMembersThroughCSV = async () => {
    const formData = new FormData();
    const userid = localStorage.getItem('userid') as string;

    if(file){
      formData.append('userid', userid);
      formData.append('csvFile',file);
      dispatch<any>(uploadCSV(formData, handleResponseAddUser));

    }else{
      enqueueSnackbar("No file is selected. Select a CSV file to continue");
      return;
    }

  };


  interface CsvRow {
    [key: string]: string;
  }


  useEffect(() => {
    if (user) {
      handleGetUser(); 
    }
    handleGetChambers();
   // console.log("hey",isNationalChamberOrAggregator)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Dialog
        open={deleteDialogOpen}
        onClose={cancelDelete}
        aria-labelledby="confirm-delete-dialog"
      >
        <DialogTitle id="confirm-delete-dialog">Confirm Delete</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this member?
        </DialogContent>
        <DialogActions>
          <Button onClick={cancelDelete} color="secondary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    <DashboardLayout>
      <POPUploadUserCSV
        isDialog={isDisabled}
        onClose={setIsDisabled}
      />
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                  Membership
                </Typography>
                {isNationalChamberOrAggregator ? (
                    <FormControl>
                     <InputLabel style={{ color: "black", backgroundColor:"white" }}>
                        Chapter
                     </InputLabel>
                     <WhiteSelect
                       label="Select Hall"
                       style={{ height: "45px", width: "200px" }}
                       sx={{
                          mb: 2,
                          mr: 1,
                          color: "#6c5ce7",
                          "& .MuiSelect-select": {
                            color: "black"
                          },
                          border: "1px  black solid",
                          "&:hover": {
                          color: "#6c5ce7",
                          border: "1px #6c5ce7 solid"
                       }}}
                       value={selectedChamberId}
                       onChange={handleChamberChange}
                       renderValue={(selectedId) => {
                        const selectedChamber =chambersData.find((chamber)=> chamber.chamber_id === selectedId);
                        return(
                          <div style={{ color: "black" }} className="selected">
                            {selectedChamberId === "default"
                            ? "Default"
                            : `${toCapitalize(selectedChamber?.first_name)} ${toCapitalize(selectedChamber?.last_name)}`}
                          </div>
                        )}}>
                       {chambersData.map((chamber) => (
                         <MenuItem sx={{color: "black"}} key={chamber.chamber_id} value={chamber.chamber_id}>
                           {toCapitalize(chamber?.first_name)} {toCapitalize(chamber?.last_name)}
                         </MenuItem>
                       ))}
                      <MenuItem sx={{color: "black"}} key={uuidv4()} value={"default"}>
                          {"Default"}
                      </MenuItem>
                     </WhiteSelect>
                    </FormControl>
                  ):null}
                  <MDBox {...({} as any)} className="ticket_btnsHeaderCont" style={{display:"flex", justifyContent:"space-between",width:"30%"}}>
                    <div>
                      <Tooltip title="Add a New Member">
                        <Button
                          onClick={() => navigate('/membership/add', { state: { type: 'add' } })}
                          variant="outlined"
                          sx={{
                            mb: 2,
                            mr: 1,
                            color: "#000",
                            border: "1px black solid",
                            "&:hover": {
                              color: "#6c5ce7",
                              border: "2px #6c5ce7 solid",
                            },
                          }}
                        >
                          Add Member
                        </Button>
                      </Tooltip>
                    </div>
                    <div>

                      <Tooltip title="Upload a New CSV">
                        <Button
                          onClick={()=>setOpenModal(true)} 
                          variant="outlined"
                          sx={{
                            mb: 2,
                            mr: 1,
                            color: "#000",
                            border: "1px black solid",
                            "&:hover": {
                              color: "#6c5ce7",
                              border: "2px #6c5ce7 solid",
                            },
                          }}
                        >
                          UPLOAD CSV
                        </Button>
                      </Tooltip>
                      <Modal open={openModal} onClose={()=>setOpenModal(false)} aria-labelledby="modal-title" aria-describedby="modal-description">
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '30%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: 400,
                            bgcolor: 'background.paper',
                            border: '0px solid #000',
                            boxShadow: 24,
                            p: 3,
                            borderRadius: 2,
                          }}
                        >
                          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", width:"100%"}}>
                            <Typography id="modal-title" variant="h6" component="h2">
                              Upload CSV
                            </Typography>
                            <Typography id="modal-title"  onClick={()=>setOpenModal(false)} variant="h6" component="h2" style={{cursor:"pointer"}}>
                              x
                            </Typography>
                          </div>
                         
                          <Typography id="modal-description" sx={{ mt: 2 }}>
                          <form onSubmit={(e)=>{
                            e.preventDefault();
                            addMembersThroughCSV();
                          }} style={{width:"100%",display:"flex",flexDirection:"column",alignItems:"left"}}>
                            <div>
                              <input 
                              style={{ width:"90%",height:"50%"}}
                                type={"file"} 
                                accept={".csv , .xlsx"} 
                                onChange={handleOnChangeCSVFile}
                                />
                            </div>
                            <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
                              <button
                                type="submit"
                                style={{backgroundColor:"white", borderStyle:"inherit", borderRadius:"10px", border:"1px solid black", width:"130px", height:"40px",fontWeight:"700", cursor:"pointer", marginTop:"20px"}}
                              >
                                Submit
                              </button> 
                            </div>
                          </form>
                          </Typography>
                          {/* Button to close the modal */}
                         
                        </Box>
                      </Modal>
                    </div>
                  </MDBox>  
                </Box>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {usersData && !loading ? (
                  usersData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No member found. Please add a member to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>
    </>
  );
};

export default MemberShip;
