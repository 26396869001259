import React, { useState, useEffect } from "react";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import axios from "axios";
import { Grid, CircularProgress, FormControl, Select, MenuItem, Box, Card, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import MDButton from "../../../components/MDButton";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import DataTable from "../../../examples/Tables/DataTable";
import { Event } from "../../../utils/types";

type Registration = {
    guest_name: string;
    guest_email: string;
    guest_contact: string;
};

const EventRegistration: React.FC = () => {
    const [selectedEvent, setSelectedEvent] = useState("");
    const [eventsData, setEventsData] = useState<Event[]>([]);
    const [registrationData, setRegistrationData] = useState<Registration[]>([]);
    const [loading, setLoading] = useState(true);
    const [tableLoading, setTableLoading] = useState(false);
    const [hasClickedView, setHasClickedView] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const token = localStorage.getItem("token");
    const Id = localStorage.getItem("user_id");

    const handleEventChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSelectedEvent(event.target.value as string);
        setRegistrationData([]);
        setHasClickedView(false);
    };

    useEffect(() => {
        const fetchEventsData = async () => {
            try {
                const response = await axios.post(
                    "https://api.pixacci.com/event/chamber",
                    { chamber_id: Id },
                    { headers: { Authorization: `Bearer ${token}` } }
                );

                setEventsData(response.data.data || []);
                setLoading(false);
            } catch (error) {
                console.error("Error fetching Event data:", error);
                enqueueSnackbar("Failed to fetch Event data. Please try again later.", { variant: "error" });
                setLoading(false);
            }
        };

        fetchEventsData();
    }, [Id, token, enqueueSnackbar]);

    const handleViewRegistrations = async () => {
        if (!selectedEvent) {
            enqueueSnackbar("Please select an event to view registrations.", { variant: "warning" });
            return;
        }

        setHasClickedView(true);
        setTableLoading(true);
        try {
            const response = await axios.post(
                `https://api.pixacci.com/eventRegister/event/${selectedEvent}`,
                { headers: { Authorization: `Bearer ${token}` } }
            );
            setRegistrationData(response.data.data || []);
        } catch (error) {
            console.error("Error fetching registration data:", error);
            enqueueSnackbar("Failed to fetch registrations. Please try again later.", { variant: "error" });
        } finally {
            setTableLoading(false);
        }
    };

    const columns = [
        { Header: "S.No", accessor: "sno", align: "center", width: "10%" },
        { Header: "Guest Name", accessor: "guest_name", align: "left", width: "30%" },
        { Header: "Guest Email", accessor: "guest_email", align: "left", width: "30%" },
        { Header: "Contact", accessor: "contact", align: "center", width: "30%" },
    ];

    const rows = registrationData.map((row, index) => ({
        sno: index + 1,
        guest_name: row.guest_name,
        guest_email: row.guest_email,
        contact: row.guest_contact,
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Box pt={6} pb={3}>
                <Grid container spacing={3} justifyContent="flex-start">
                    <Grid item xs={12}>
                        <Card>
                            <Box pt={3}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" px={3} py={2}>
                                    <Typography variant="h5" color="black" sx={{ ml: 1, mb: -2 }}>
                                        Event Registration
                                    </Typography>
                                </Box>
                            </Box>
                            <MDBox {...({} as any)} mx={2} mt={3} py={3} p={4} mb={2} variant="gradient" borderRadius="lg">
                                <Grid container direction="row" justifyContent="space-between" alignItems="center" gap={3} pb={0.5}>
                                    <Grid item xs={12} md={6}>
                                        <label>
                                            <MDTypography {...({} as any)} variant="h6" style={{ marginBottom: "10px", marginLeft: "10px" }}>
                                                Select Events
                                            </MDTypography>
                                            <FormControl fullWidth>
                                                {loading ? (
                                                    <CircularProgress size={24} />
                                                ) : eventsData.length > 0 ? (
                                                    <Select {...({} as any)} style={{ height: "45px" }} value={selectedEvent} onChange={handleEventChange}>
                                                        {eventsData.map((event) => (
                                                            <MenuItem key={event.event_id} value={event.event_id}>
                                                                {event?.event_name}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                ) : (
                                                    <Typography>No Events available</Typography>
                                                )}
                                            </FormControl>
                                        </label>
                                    </Grid>
                                </Grid>
                                <MDBox {...({} as any)} mt={2}>
                                    <MDButton
                                        {...({} as any)}
                                        variant="gradient"
                                        style={{ color: "white", backgroundColor: "#6c5ce7" }}
                                        onClick={handleViewRegistrations}
                                    >
                                        {tableLoading ? <CircularProgress size={20} color="inherit" /> : "View Registrations"}
                                    </MDButton>
                                </MDBox>
                                <MDBox {...({} as any)} mt={4}>
                                    {!tableLoading && !hasClickedView ? (
                                        <Typography>Please select an event</Typography>
                                    ) : !tableLoading && registrationData.length > 0 ? (
                                        <DataTable table={{ columns, rows }} isSorted={false} noEndBorder />
                                    ) : (
                                        !tableLoading && hasClickedView && <Typography>No registrations found</Typography>
                                    )}
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </Box>
        </DashboardLayout>
    );
};

export default EventRegistration;
