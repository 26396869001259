import axios, { AxiosResponse } from "axios";
import { userEndpoint } from "../utils/API_endpoint";
import { actionTypes } from "./action.types";
import { Base_URL } from "../utils/Base_URL";
import { AppDispatch } from "./store";
import { Equipment } from "../utils/types";
import { checkAuth } from "../utils/authUtil";
import { enqueueSnackbar } from "notistack";
// Define types for action payloads
import { Chamber } from "../utils/types";
interface HandleResponse {
  (success: boolean, res?: any): void;
}

const token = localStorage.getItem("token");

// Membership actions

// Get all the users by chamber id
export const GetUser =
  (chamber_id: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETUSER_LOADING });
    if (!checkAuth()) return;

    try {
      //const chamber_id = localStorage.getItem("user_id");
      let res = await axios.get(
        `https://api.pixacci.com/chamber/${chamber_id}/users`,
        {
          params: {
            chamber_id: chamber_id,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        dispatch({ type: actionTypes.GETUSER_SUCCESS, payload: res.data.data });
      }
      if (res.data) {
        return res.data;
      }
      return;
    } catch (error) {
      dispatch({ type: actionTypes.GETUSER_ERROR });
      throw error;
    }
  };

// Get users by id
export const GetUserById =
  (id: number, handleInitialCities: (stateCode: string) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETUSERBYID_LOADING });
    if (!checkAuth()) return;

    try {
      let res = await axios.get(`${Base_URL}${userEndpoint.getUserById(id)}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        dispatch({
          type: actionTypes.GETUSERBYID_SUCCESS,
          payload: res.data.data,
        });
        handleInitialCities(res.data.data.state);
      }
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETUSERBYID_ERROR });
    }
  };

// Add User
export const AddUser =
  (details: FormData, handleResponseAddUser: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.ADDUSER_LOADING });
    try {
      let res = await axios.post(
        `${Base_URL}${userEndpoint.addUser}`,
        details,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data.success) {
        handleResponseAddUser(res.data.success, res);
        dispatch({ type: actionTypes.ADDUSER_SUCCESS, payload: res.data });
        return;
      }
      handleResponseAddUser(res.data.success, res);
      return res.data;
    } catch (error) {
      dispatch({ type: actionTypes.ADDUSER_ERROR });
      handleResponseAddUser(false);
      throw error;
    }
  };

// Update the user
export const UpdateUser =
  (id: number, changes: FormData, handleResponseUpdateUser: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    if (!checkAuth()) return;

    try {
      const res = await axios.put(
        `${Base_URL}${userEndpoint.updateUser(id)}`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: actionTypes.UPDATEUSER_SUCCESS, payload: res.data });
      if (res.data) {
        handleResponseUpdateUser(res.data.success, res);
      }
    } catch (error) {
      dispatch({ type: actionTypes.UPDATEUSER_ERROR });
      handleResponseUpdateUser(false);
      throw error;
    }
  };

// Delete the user
export const DeleteUser =
  (user_id: number) => async (dispatch: AppDispatch) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token is missing");
      }

      const res = await axios.delete(
        `${Base_URL}${userEndpoint.deleteUser(user_id)}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      dispatch({ type: actionTypes.DELETEUSER_SUCCESS, payload: res.data });
    } catch (error: any) {
      console.error("Error deleting user:", error);
      dispatch({ type: actionTypes.DELETEUSER_ERROR, error: error.message });
    }
  };

// Get Chamber by id
export const GetChamberById = (id: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.GETCHAMBERBYID_LOADING });
  const id = localStorage.getItem("user_id");

  try {
    let res = await axios.get(`https://api.pixacci.com/chamber/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (res) {
      dispatch({
        type: actionTypes.GETCHAMBERBYID_SUCCESS,
        payload: res.data.data,
      });
    }
    localStorage.setItem(
      "service_ids",
      JSON.stringify(res.data?.data?.services)
    );
    dispatch({
      type: actionTypes.GETservice_id_SUCCESS,
      payload: res.data?.data?.service_ids,
    });
    return res.data.data;
  } catch (error) {
    dispatch({ type: actionTypes.GETCHAMBERBYID_ERROR });
  }
};

// Hall Management actions

// Get  all halls
export const GetHalls = (id: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.GETHALL_LOADING });
  if (!checkAuth()) return;

  try {
    //const id = localStorage.getItem("user_id");
    let res = await axios.post(
      "https://api.pixacci.com/hall/chamber",
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res) {
      dispatch({ type: actionTypes.GETHALL_SUCCESS, payload: res.data.data });
    }
    return res.data;
  } catch (error) {
    dispatch({ type: actionTypes.GETHALL_ERROR });
    throw error;
  }
};

// Get all Halls for the chosen chamber by national chamber
// export const GetHallsByChamberId = (id:string) => async (dispatch: AppDispatch) => {
//   dispatch({ type: actionTypes.GETHALL_LOADING });
//   if (!checkAuth()) return;
//   try {
//     let res = await axios.post("https://api.pixacci.com/hall/chamber", { id }, {
//       headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//     if (res) {
//       dispatch({ type: actionTypes.GETHALL_SUCCESS, payload: res.data.data });
//     }
//     return res.data;
//   } catch (error) {
//     dispatch({ type: actionTypes.GETHALL_ERROR });
//     throw error;
//   }
// };

// Get all Chambers
export const GetChambers = () => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.GETCHAMBERS_LOADING });
  if (!checkAuth()) return;

  try {
    const user_id = JSON.parse(
      localStorage.getItem("user_id") as string
    ) as number;
    const isNationalChamber = localStorage.getItem("isNationalChamber");
    // const isAggregator = localStorage.getItem("isAggregator");

    let res = await axios.post(
      "https://api.pixacci.com/chamber/getchambersbyparent_id",
      isNationalChamber
        ? { parent_id: user_id, aggregator_id: null }
        : { parent_id: null, aggregator_id: user_id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res) {
      dispatch({
        type: actionTypes.GETCHAMBERS_SUCCESS,
        payload: res.data.data as any[],
      });
    }
    return res.data;
    // const res={data:[{chamber_id:"1"},{chamber_id:"2"},{chamber_id:"3"},{chamber_id:"4"}]}
    // dispatch({ type: actionTypes.GETCHAMBERS_SUCCESS, payload:res.data });
    // return  res;
  } catch (error) {
    //console.log("error in getting chambers", error);
    dispatch({ type: actionTypes.GETCHAMBERS_ERROR });
    throw error;
  }
};

// Get single hall by ID
export const GetHallById =
  (id: number, handleInitialCities: (stateCode: string) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETHALLBYID_LOADING });
    if (!checkAuth()) return;

    try {
      let res = await axios.get(`https://api.pixacci.com/hall/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        dispatch({
          type: actionTypes.GETHALLBYID_SUCCESS,
          payload: res.data.data,
        });
        handleInitialCities(res.data.data.state);
      }
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETHALLBYID_ERROR });
    }
  };

// Add new hall
export const AddHall =
  (details: FormData, handleResponseAddHall: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.ADDHALL_LOADING });
    try {
      let res = await axios.post(`https://api.pixacci.com/hall`, details, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res)
      if (res.data.success) {
        handleResponseAddHall(res.data.success, res);
        dispatch({ type: actionTypes.ADDHALL_SUCCESS, payload: res.data.data });
      }
      handleResponseAddHall(res.data.success, res);
      return res.data;
    } catch (error) {
      dispatch({ type: actionTypes.ADDHALL_ERROR });
      handleResponseAddHall(false);
      throw error;
    }
  };

// Update Hall Details
export const UpdateHall =
  (id: number, changes: FormData, handleResponseUpdateUser: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.UPDATEHALL_LOADING });
    try {
      const res = await axios.put(
        `https://api.pixacci.com/hall/${id}`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: actionTypes.UPDATEHALL_SUCCESS,
        payload: res.data.data,
      });
      if (res.data) {
        handleResponseUpdateUser(res.data.success, res);
      }
    } catch (error) {
      dispatch({ type: actionTypes.UPDATEHALL_ERROR });
      handleResponseUpdateUser(false);
      throw error;
    }
  };

// Delete hall by id
export const DeleteHallById = (id: number) => async (dispatch: AppDispatch) => {
  try {
    const res = await axios.delete(`https://api.pixacci.com/hall/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({ type: actionTypes.DELETEHALL_SUCCESS, payload: res.data });
    // console.log("DeleteEquipmentsById", res);
  } catch (error) {
    dispatch({ type: actionTypes.DELETEHALL_ERROR });
  }
};

//Lounge management functions

// Get  all lounge
export const GetLounge = (id: string) => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.GETLOUNGE_LOADING });
  if (!checkAuth()) return;

  try {
    // const id = localStorage.getItem("user_id");
    let res = await axios.post(
      "https://api.pixacci.com/lounge/chamber",
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (res) {
      dispatch({ type: actionTypes.GETLOUNGE_SUCCESS, payload: res.data.data });
    }
    return res.data;
  } catch (error) {
    dispatch({ type: actionTypes.GETLOUNGE_ERROR });
    throw error;
  }
};

// export const GetLoungesByChamberId = (id:string) => async (dispatch: AppDispatch) => {
//   dispatch({ type: actionTypes.GETLOUNGE_LOADING });
//   if (!checkAuth()) return;
//   try {
//     let res = await axios.post("https://api.pixacci.com/lounge/chamber", { id }, {
//       headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
//     if (res) {
//       dispatch({ type: actionTypes.GETLOUNGE_SUCCESS, payload: res.data.data });
//     }
//     return res.data;
//   } catch (error) {
//     dispatch({ type: actionTypes.GETLOUNGE_ERROR });
//     throw error;
//   }
// };

// Get single lounge by ID
export const GetLoungeById =
  (id: number, handleInitialCities: (stateCode: string) => void) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETHALLBYID_LOADING });
    if (!checkAuth()) return;

    try {
      let res = await axios.get(`https://api.pixacci.com/lounge/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (res) {
        dispatch({
          type: actionTypes.GETLOUNGEBYID_SUCCESS,
          payload: res.data.data,
        });
        handleInitialCities(res.data.data.state);
      }
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETLOUNGEBYID_ERROR });
    }
  };

// Add new lounge
export const AddLounge =
  (details: FormData, handleResponseAddLounge: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.ADDLOUNGE_LOADING });
    try {
      let res = await axios.post(`https://api.pixacci.com/lounge`, details, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res)
      if (res.data.success) {
        handleResponseAddLounge(res.data.success, res);
        dispatch({
          type: actionTypes.ADDLOUNGE_SUCCESS,
          payload: res.data.data,
        });
      }
      handleResponseAddLounge(res.data.success, res);
      return res.data;
    } catch (error) {
      dispatch({ type: actionTypes.ADDLOUNGE_ERROR });
      handleResponseAddLounge(false);
      throw error;
    }
  };

// Update lounge Details
export const UpdateLounge =
  (id: number, changes: FormData, handleResponseUpdateUser: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.UPDATELOUNGE_LOADING });
    try {
      const res = await axios.put(
        `https://api.pixacci.com/lounge/${id}`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: actionTypes.UPDATELOUNGE_SUCCESS,
        payload: res.data.data,
      });
      if (res.data) {
        handleResponseUpdateUser(res.data.success, res);
      }
    } catch (error) {
      dispatch({ type: actionTypes.UPDATELOUNGE_ERROR });
      handleResponseUpdateUser(false);
      throw error;
    }
  };

// Delete lounge by id
export const DeleteLoungeById =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      const res = await axios.delete(`https://api.pixacci.com/lounge/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: actionTypes.DELETELOUNGE_SUCCESS, payload: res.data });
    } catch (error) {
      dispatch({ type: actionTypes.DELETELOUNGE_ERROR });
    }
  };

// Equipment Management actions

// Get equipments by id
export const GetEquipmentsById =
  (id: number) => async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETEQUIPSBYID_LOADING });
    if (!checkAuth()) return;

    try {
      let res: AxiosResponse<{ success: boolean; data: Equipment }> =
        await axios.get(`https://api.pixacci.com/equipment/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      if (res) {
        dispatch({
          type: actionTypes.GETEQUIPSBYID_SUCCESS,
          payload: res.data.data,
        });
      }
      // console.log("GetEquipmentsById", res.data.data);
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETEQUIPSBYID_ERROR });
    }
  };

// get equipments by hall id
export const GetAllEquipmentsByHallId =
  (hall_id: number) => async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETEQUIPSBYHALLID_LOADING });
    if (!checkAuth()) return;

    try {
      let res = await axios.post(
        "https://api.pixacci.com/equipment/hall",
        {
          hall_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        dispatch({
          type: actionTypes.GETEQUIPSBYHALLID_SUCCESS,
          payload: res.data.data,
        });
      }
      // console.log("GetAllEquipmentsByHallId", res);
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETEQUIPSBYHALLID_ERROR });
      throw error;
    }
  };

// Add equipments
export const AddEquipments =
  (details: FormData, handleResponseAddEquipments: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.ADDEQUIP_LOADING });
    try {
      let res = await axios.post(`https://api.pixacci.com/equipment`, details, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res)
      if (res.data.success) {
        handleResponseAddEquipments(res.data.success, res);
        dispatch({
          type: actionTypes.ADDEQUIP_SUCCESS,
          payload: res.data.data,
        });
      }
      handleResponseAddEquipments(res.data.success, res);
      // console.log("AddEquipments", res.data);
      return res.data;
    } catch (error) {
      dispatch({ type: actionTypes.ADDEQUIP_ERROR });
      handleResponseAddEquipments(false);
      throw error;
    }
  };

// Update equipments by id
export const UpdateEquipmentById =
  (
    id: number,
    changes: FormData,
    handleResponseUpdateEquipment: HandleResponse
  ) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.UPDATEEQUIP_LOADING });
    try {
      const res = await axios.put(
        `https://api.pixacci.com/equipment/${id}`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: actionTypes.UPDATEEQUIP_SUCCESS,
        payload: res.data.data,
      });
      if (res.data) {
        handleResponseUpdateEquipment(res.data.success, res);
      }
    } catch (error) {
      dispatch({ type: actionTypes.UPDATEEQUIP_ERROR });
      handleResponseUpdateEquipment(false);
      throw error;
    }
  };

// Delete the equipments
export const DeleteEquipmentsById =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      const res = await axios.delete(
        `https://api.pixacci.com/equipment/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({ type: actionTypes.DELETEEQUIP_SUCCESS, payload: res.data });
      //console.log("DeleteEquipmentsById", res);
    } catch (error) {
      dispatch({ type: actionTypes.DELETEEQUIP_ERROR });
    }
  };

// Event Management actions

// Get events by chamber id
export const GetEventsByChamberId =
  (chamber_id: string) => async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.GETEQUIPSBYHALLID_LOADING });
    if (!checkAuth()) return;

    try {
      // const chamber_id = localStorage.getItem("user_id");
      let res = await axios.post(
        "https://api.pixacci.com/event/chamber",
        {
          chamber_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res) {
        dispatch({
          type: actionTypes.GETEVENTBYCHAMBERID_SUCCESS,
          payload: res.data.data,
        });
      }
      // console.log("GetEventsByChamberId", res);
      return res.data.data;
    } catch (error) {
      dispatch({ type: actionTypes.GETEVENTBYCHAMBERID_ERROR });
      throw error;
    }
  };

// Get events by id
export const GetEventsById = (id: number) => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.GETEVENTBYID_LOADING });
  if (!checkAuth()) return;

  try {
    let res: AxiosResponse<{ success: boolean; data: Equipment }> =
      await axios.get(`https://api.pixacci.com/event/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    if (res) {
      dispatch({
        type: actionTypes.GETEVENTBYID_SUCCESS,
        payload: res.data.data,
      });
    }
    // console.log("GetEventsById", res.data.data);
    return res.data.data;
  } catch (error) {
    dispatch({ type: actionTypes.GETEVENTBYID_ERROR });
  }
};
export const uploadCSV = (details: FormData, handleResponseAddUser: HandleResponse) => async (dispatch: AppDispatch) => {
  dispatch({ type: actionTypes.ADDUSER_LOADING });
  try {
    let res = await axios.post(
      //`${Base_URL}${userEndpoint.addUser}`,
      'https://api.pixacci.com/user/importuserbyexcel',
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    );
    if (res.data.success) {
      handleResponseAddUser(res.data.success, res);
      dispatch({ type: actionTypes.ADDUSER_SUCCESS, payload: res.data });
      return;
    }
    handleResponseAddUser(res.data.success, res);
    return res.data;
  } catch (error) {
    dispatch({ type: actionTypes.ADDUSER_ERROR });
    handleResponseAddUser(false);
    throw error;
  }
};
// Add events
export const AddEvents =
  (details: any, handleResponseAddEvents: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.ADDEVENT_LOADING });
    try {
      let res = await axios.post(`https://api.pixacci.com/event`, details, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // console.log(res)
      if (res.data.success) {
        handleResponseAddEvents(res.data.success, res);
        dispatch({
          type: actionTypes.ADDEVENT_SUCCESS,
          payload: res.data.data,
        });
      }
      handleResponseAddEvents(res.data.success, res);
      // console.log("AddEvents", res);
      return res.data;
    } catch (error) {
      dispatch({ type: actionTypes.ADDEVENT_ERROR });
      handleResponseAddEvents(false);
      throw error;
    }
  };

// Update equipments by id
export const UpdateEventById =
  (id: number, changes: any, handleResponseUpdateEvents: HandleResponse) =>
  async (dispatch: AppDispatch) => {
    dispatch({ type: actionTypes.UPDATEEVENT_LOADING });
    try {
      const res = await axios.put(
        `https://api.pixacci.com/event/${id}`,
        changes,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      dispatch({
        type: actionTypes.UPDATEEVENT_SUCCESS,
        payload: res.data.data,
      });
      // console.log("UpdateEventById", res);
      if (res.data) {
        handleResponseUpdateEvents(res.data.success, res);
      }
    } catch (error) {
      dispatch({ type: actionTypes.UPDATEEVENT_ERROR });
      handleResponseUpdateEvents(false);
      throw error;
    }
  };

// Delete events by id
export const DeleteEventsById =
  (id: number) => async (dispatch: AppDispatch) => {
    try {
      const res = await axios.delete(`https://api.pixacci.com/event/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch({ type: actionTypes.DELETEEVENT_SUCCESS, payload: res.data });
      // console.log("DeleteEquipmentsById", res);
    } catch (error) {
      dispatch({ type: actionTypes.DELETEEVENT_ERROR });
    }
  };
