import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from 'react';
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import { checkAuth } from "utils/authUtil";
import { enqueueSnackbar } from "notistack";
import axios from "axios";
import PeopleIcon from '@mui/icons-material/People';
import DomainIcon from '@mui/icons-material/Domain';
import WeekendIcon from '@mui/icons-material/Weekend';
import EventIcon from '@mui/icons-material/Event';

function Dashboard() {
  const [dashboardData, setDashboardData] = useState({
    events: 0,
    members: 0,
    halls: 0,
    lounges: 0,
  });

  const authToken = localStorage.getItem("token");
  const chamberId = localStorage.getItem("user_id");
  const fetchDashboardData = async () => {
    if (!checkAuth()) return;

    try {
      const res = await axios.post(
        `https://api.pixacci.com/dashboard/dashboarddetails`,
        {"role":"chamber", "chamber_id": chamberId},
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      if (res.data.success) {
        const { totalHalls, totalEvents, totalLounges, totalUsers } = res.data.data;
        setDashboardData({
          events: totalEvents,
          members: totalUsers,
          halls: totalHalls,
          lounges: totalLounges,
        });
      } else {
        enqueueSnackbar("Failed to fetch dashboard data", { variant: "error" });
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      enqueueSnackbar("Failed to fetch dashboard data", { variant: "error" });
    }
  };

  useEffect(() => {
    fetchDashboardData();
  }, [authToken]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<PeopleIcon />}
                title="Members"
                count={dashboardData.members}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<DomainIcon />}
                title="Halls"
                count={dashboardData.halls}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<WeekendIcon />}
                title="Lounges"
                count={dashboardData.lounges}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon={<EventIcon />}
                title="Events"
                count={dashboardData.events}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
}

export default Dashboard;