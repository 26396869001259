// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import {v4 as uuidv4} from "uuid";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import WhiteSelect from "../../components/WhiteSelect";
import { useEffect, useState } from "react";
import { DeleteEventsById, GetEventsByChamberId, GetChambers } from "../../Redux/action";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Box,
  Typography,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  styled,
  Select
} from "@mui/material";
import { useSnackbar } from "notistack";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toCapitalize } from "../../utils/capitalize";
const EventManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    date,
  }: {
    image: string;
    name: string;
    date: string;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar {...({} as any)} src={image} name={name} size="sm" />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          happening on {date}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [isNationalChamberOrAggregator, setIsNationalChamberOrAggregator]= useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { eventsByChamberId, loading } = useAppSelector(
    (store: RootState) => store.EventReducer
  );
  const {chambers, loadingc} = useAppSelector(
    (store: RootState) => store.GetChambersReducer
  );
  const eventsData = eventsByChamberId || [];
  const chambersData = chambers || [];
  const [selectedChamberId, setSelectedChamberId]  = useState<null | string>("");
  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/event-management/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async () => {
    try {

      await dispatch<any>(DeleteEventsById(deleteId!));
      const chamber_id = localStorage.getItem("user_id") as string;

      dispatch<any>(GetEventsByChamberId(chamber_id));
      handleClose();
      enqueueSnackbar("Event is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting event:", error);
    }
  };



  const handleGetEvents = async () => {
    try {
      const chamber_id = localStorage.getItem("user_id") as string;
      await dispatch<any>(GetEventsByChamberId(chamber_id));
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const handleGetChambers = async ()=>{
    try{
      const isNational = localStorage.getItem("isNationalChamber") as string;
      const isAggregator = localStorage.getItem("isAggregator") as string ;
      if(isNational || isAggregator){
        const boolNationalChamber=JSON.parse(isNational);
        const boolAggregator = JSON.parse(isAggregator);
        if(boolNationalChamber)setIsNationalChamberOrAggregator(boolNationalChamber);
        else if(boolAggregator)setIsNationalChamberOrAggregator(boolAggregator);
        
        if(boolNationalChamber || boolAggregator){
          await dispatch<any>(GetChambers());
          
        }

      }
    }catch(error){
      console.error("Error fetching Chamber detail: ", error);
    }
  }

  const handleChamberChange = (event: SelectChangeEvent<any>)=>{
    const chamberId = event.target.value;
    if(chamberId==="default"){
      handleGetEvents();
      setSelectedChamberId(chamberId);
      return;
    }
    setSelectedChamberId(chamberId);
    dispatch<any>(GetEventsByChamberId(chamberId));

  }
  const columns = [
    { Header: "event name", accessor: "event name", align: "left" },
    { Header: "agenda", accessor: "agenda", align: "left" },
    {
      Header: "registration date till",
      accessor: "registration date till",
      align: "center",
    },
    { Header: "time", accessor: "time", align: "center" },
    ...(selectedChamberId && selectedChamberId !== "default"
      ? []
      : [{ Header: "action", accessor: "action", align: "center" }]),
  ];

  const rows = eventsData?.map((event) => ({
    "event name": (
      <Author
        image={
          "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
        }
        name={`${toCapitalize(event?.event_name)}`}
        date={`${event?.event_date}`}
      />
    ),
    agenda: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {event?.event_agenda}
      </MDTypography>
    ),
    "registration date till": (
      <MDTypography {...({} as any)}>
        <MDBadge
          {...({} as any)}
          badgeContent={`${event?.event_registration_date}`}
          color={"success"}
          variant="gradient"
          size="sm"
        />
      </MDTypography>
    ),
    time: (
      // <MDBox {...({} as any)} ml={-1}>
      //   <MDBadge
      //     {...({} as any)}
      //     badgeContent={event?.event_time_from}
      //     color={"success"}
      //     variant="gradient"
      //     size="sm"
      //   />
      //   <MDBadge
      //     {...({} as any)}
      //     color={"success"}
      //     badgeContent={event?.event_time_till}
      //     variant="gradient"
      //     size="sm"
      //   />
      // </MDBox>
      <MDTypography
        {...({} as any)}
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {event?.event_time_from} - {event?.event_time_till}
      </MDTypography>
    ),
    action:
      selectedChamberId === "default" || !selectedChamberId ? (
        <MDBox {...({} as any)} display="flex" justifyContent="center" gap={1}>
          <Tooltip title="Edit">
            <IconButton color="primary" onClick={() => handleEditClick(event?.event_id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton color="error"
              onClick = {() => {
              if (event.event_id) handleClickOpen(event?.event_id);
             }}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </MDBox>
      ) : null,
  }));
  useEffect(() => {
    handleGetEvents();
    handleGetChambers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box pt={3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  px={3}
                  py={2}
                >
                  <Typography
                    variant="h5"
                    color="black"
                    sx={{ ml: 1, mb: 2 }}
                  >
                    Event Management
                  </Typography>
                  {isNationalChamberOrAggregator ? (
                    <FormControl>
                     <InputLabel style={{ color: "black" , backgroundColor:"white"}}>
                       Chapter
                     </InputLabel>
                     <WhiteSelect
                       label="Select Hall"
                       style={{ height: "45px", width: "200px" }}
                       sx={{
                          mb: 2,
                          mr: 1,
                          color: "#6c5ce7",
                          "& .MuiSelect-select": {
                            color: "black"
                          },
                          border: "1px  black solid",
                          "&:hover": {
                          color: "#6c5ce7",
                          border: "1px #6c5ce7 solid"
                       }}}
                       value={selectedChamberId}
                       onChange={handleChamberChange}
                       renderValue={(selectedId) => {
                      const selectedChamber =chambersData.find((chamber)=> chamber.chamber_id === selectedId);
                      return(
                        <div style={{ color: "black" }} className="selected">
                          {selectedChamberId === "default"
                            ? "Default"
                            : `${toCapitalize(selectedChamber?.first_name)} ${toCapitalize(selectedChamber?.last_name)}`}
                          </div>
                        )
                       }}
                     >
                       {chambersData.map((chamber) => (
                         <MenuItem sx={{color: "black"}} key={chamber.chamber_id} value={chamber.chamber_id}>
                           {toCapitalize(chamber?.first_name)} {toCapitalize(chamber?.last_name)}
                         </MenuItem>
                       ))}
                      <MenuItem sx={{color: "black"}} key={uuidv4()} value={"default"}>
                          {"Default"}
                      </MenuItem>
                     </WhiteSelect>
                    </FormControl>
                  ):null}
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                    <Tooltip title="View Event Registration">
                      <Button
                        onClick={() => navigate("/event-management/registration")}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid",
                          },
                        }}
                      >
                        View Registration
                      </Button>
                    </Tooltip>
                  <Tooltip title="Add a new event">
                      <Button
                        onClick={() => {
                          navigate("/event-management/add", { state: { type: "add" } });
                        }}
                        variant="outlined"
                        sx={{
                          mb: 2,
                          mr: 1,
                          color: "#000",
                          border: "1px black solid",
                          "&:hover": {
                            color: "#6c5ce7",
                            border: "2px #6c5ce7 solid"
                          }
                        }}
                      >
                        Add Event
                      </Button>
                  </Tooltip>
                </MDBox>
              </Box>
              <MDBox {...({} as any)} pt={3}>
                {eventsData && !loading ? (
                  eventsData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No event found. Please add an event to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
              </Box>
            </Card>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this event?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <MDButton {...({} as any)} onClick={handleClose}>
                Disagree
              </MDButton>
              <MDButton {...({} as any)} onClick={handleDeleteClick} autoFocus>
                Agree
              </MDButton>
            </DialogActions>
          </Dialog>
          </Grid>
        </Box>
    </DashboardLayout>
  );
};

export default EventManagement;
